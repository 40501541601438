<template>
  <div
    id="modal-delete-confirm"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-delete-global"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div class="uk-modal-title uk-text-danger">
        <img
          v-lazy="`${images}/icon/warning.svg`"
          alt=""
          class="uk-margin-small-right"
        >
        Hapus {{ propsPayload.title }}
      </div>
      <p style="font-size:15px">
        Apakah kamu yakin ingin menghapus <br> "{{ propsPayload.description }}" ?
      </p>
      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-default"
          type="button"
          :disabled="is_loading"
          @click="handleDelete"
        >
          <span
            v-if="is_loading"
            uk-spinner
            class="button-spinner"
          />Ya, hapus
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-left uk-modal-close"
          type="button"
          :disabled="is_loading"
        >
          Tidak
        </button>
      </div>
    </div>

    <modal-delete-failed />
  </div>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import ModalDeleteFailed from './DeleteFailed'
import {
  notificationSuccess
} from '@/utils/notification'

export default {
  components: {
    ModalDeleteFailed
  },
  props: {
    propsPayload: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE
    }
  },
  methods: {
    async handleDelete () {
      try {
        this.is_loading = true
        await this.propsPayload.function(this.propsPayload.id)
        const res = await this.$store.getters[this.propsPayload.getters]
        if (res.message === 'OK') {
          notificationSuccess(`Hapus "${this.propsPayload.description}" Berhasil`)
          await this.propsPayload.fetchingData()
        } else {
          window.UIkit.modal('#modal-delete-failed').show()
        }
        this.is_loading = false
        window.UIkit.modal('#modal-delete-confirm').hide()
      } catch (error) {
        window.UIkit.modal('#modal-delete-failed').show()
        this.is_loading = false
      }
    }
  }
}
</script>
