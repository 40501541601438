<template>
  <div>
    <div class="uk-form-horizontal filter-container">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-first-column">
          <label class="uk-form-label filter-margin"> Kode Akuisisi </label>
          <div class="uk-form-controls">
            <input
              v-model="metaFilter.acquisition_code"
              class="uk-input"
              type="text"
              placeholder="Cari nomor akuisisi"
            >
          </div>
        </div>
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-first-column">
          <label class="uk-form-label filter-margin"> Nama Calon Mitra </label>
          <div class="uk-form-controls">
            <input
              v-model="metaFilter.name"
              class="uk-input"
              type="text"
              placeholder="Cari nama calon mitra"
            >
          </div>
        </div>
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
          <label class="uk-form-label filter-margin"> Tipe Kandang </label>
          <div class="uk-form-controls">
            <multiselect
              v-model="metaFilter.cage_category_id"
              label="name"
              name="cage_type"
              track-by="cage_name"
              placeholder="Semua"
              open-direction="bottom"
              :options="dataListCage.data"
              :searchable="true"
              :loading="dataListCage.isLoading"
              :close-on-select="true"
              :max-height="500"
              :show-no-results="true"
              @search-change="handleSearch"
            >
              <span slot="noResult">Oops! Data tidak ditemukan.</span>
            </multiselect>
          </div>
        </div>
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
          <label class="uk-form-label filter-margin"> Nama PPL </label>
          <div class="uk-form-controls">
            <input
              v-model="metaFilter.field_supervisor"
              class="uk-input"
              type="text"
              placeholder="Cari Nama PPL"
            >
          </div>
        </div>
      </div>

      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-first-column">
          <label class="uk-form-label filter-margin"> Status Mitra </label>
          <div class="uk-form-controls">
            <multiselect
              v-model="metaFilter.status_acquisition"
              name="partner-status"
              :show-labels="true"
              placeholder="Semua"
              open-direction="bottom"
              :options="dataListStatus.data"
              :searchable="true"
              :loading="dataListStatus.isLoading"
              :close-on-select="true"
              :max-height="500"
              :show-no-results="true"
            >
              <span slot="noResult">Oops! Data tidak ditemukan.</span>
            </multiselect>
          </div>
        </div>
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-text-right">
          <button
            class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
            @click="clearFilter()"
          >
            <span
              uk-icon="icon: close; ratio: 0.9"
              class="uk-margin-small-right"
            />Clear
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            @click="handleFilter()"
          >
            <span
              uk-icon="icon: search; ratio: 0.9"
              class="uk-margin-small-right"
            />Cari
          </button>
        </div>
      </div>
    </div>
    <div
      class="uk-child-width-expand@s uk-grid-small uk-margin-top"
      uk-grid
    >
      <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l">
        <button
          v-if="isCanAdd"
          class="uk-button uk-button-default uk-width-expand uk-width-auto@s uk-margin-right"
          @click="$router.push('akuisisi/import')"
        >
          <span
            uk-icon="icon: upload; ratio: 0.7"
            class="uk-margin-small-right"
          />Import
        </button>
        <router-link
          v-if="isCanAdd"
          class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
          to="/admin/akuisisi/add"
        >
          <span
            uk-icon="icon: plus; ratio: 0.7"
            class="uk-margin-small-right"
          />Tambah Data Mitra
        </router-link>
        <button 
          v-else
          class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
          disabled
        >
          Tambah Data Mitra
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
// import { PARTNER_STATUS } from "@/utils/constant"
import { isCanAccess } from '@/utils/auth'

export default {
  data() {
    return {
      metaFilter: {
        acquisition_code: "",
        field_supervisor: "",
        name: "",
        cage_category_id: "",
        status_acquisition: ""
      },
      dataListStatus: {
        data: [],
        isLoading: true
      },
      dataListCage: {
        data: [],
        isLoading: true
      },
      debounce: null,
      isCanAdd: isCanAccess(`add`, `Akuisisi`)
    }
  },
  computed: {
    ...mapGetters({
      statusAcquisitionList: 'acquisition/statusAcquisitionLists',
      cageCategorys: "cageCategory/cageCategorys",
      meta: "cageCategory/meta",
      dataMeta: "acquisition/dataMeta"
    })
  },
  watch:{
    cageCategorys(){
      this.dataListCage = {
        data: this.cageCategorys.data,
        isLoading: false
      }
    },
    statusAcquisitionList(){
      if(this.statusAcquisitionList){
        this.dataListStatus = {
          data: this.statusAcquisitionList,
          isLoading: false
        }
      }
    }
  },
  async mounted() {
    await this.getCageCategory(this.meta)
    await this.getListStatusAcquisition()
  },
  methods: {
    ...mapActions({
      getListStatusAcquisition: "acquisition/getListStatusAcquisition",
      getCageCategory: "cageCategory/getCageCategory"
    }),
    ...mapMutations({
      setMeta: "cageCategory/SET_META",
      setDataMeta: "acquisition/SET_DATA_META"
    }),
    handleFilter() {
      this.setDataMeta({
        ...this.dataMeta,
        ...this.metaFilter,
        cage_category_id: this.metaFilter.cage_category_id ? this.metaFilter.cage_category_id.id : "",
        status_acquisition: this.metaFilter.status_acquisition ? this.metaFilter.status_acquisition : ''
      })
    },
    async handleSearch(value) {
      this.dataListCage.isLoading = true
      clearTimeout(this.debounce)
      this.debounce =  setTimeout(async() => {
        this.dataListCage.isLoading = false
        await this.getCageCategory({
          name: value
        })
      }, 600)
    },
    async clearFilter() {
      const app = this
      app.metaFilter.acquisition_code = "",
      app.metaFilter.field_supervisor = "",
      app.metaFilter.name = "",
      app.metaFilter.cage_category_id = "",
      app.metaFilter.status_acquisition = ""
      await app.handleFilter()
    }
  }
}
</script>
