<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-divider">
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Kode Akuisisi</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Status Mitra</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Nama Mitra</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Alamat</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Tipe Kandang</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Nama PPL</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Dana Jaminan</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">HP</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Genset</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Listrik</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Air</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Sarana</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold">Asisten Mitra</span>
            </th>
            <th class="uk-table-expand">
              <span class="uk-text-bold uk-display-block uk-text-center">Aksi</span>
            </th>
          </tr>
        </thead>
        <template v-if="!dataListAcquisition.isLoading">
          <tbody v-if="dataListAcquisition.meta.total_count > 0">
            <tr
              v-for="(item, index) in dataListAcquisition.data"
              :key="index"
            >
              <td>{{ item.acquisition_code || '-' }}</td>
              <td>
                {{ 
                  item.status_acquisition.includes('Paranje Farmers') ? 'Paranje Farmers' : item.status_acquisition
                }}
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.cage_category }}</td>
              <td>{{ item.field_supervisor }}</td>
              <td>{{ item.total_installment_amount || '-' }}</td>
              <td>
                <img
                  v-lazy="`${images}/icon/${item.handphone ? 'square-checked' : 'square-un-check'}.svg`"
                  alt="icon check"
                >
              </td>
              <td>
                <img
                  v-lazy="`${images}/icon/${item.generator ? 'square-checked' : 'square-un-check'}.svg`"
                  alt="icon check"
                >
              </td>
              <td>
                <img
                  v-lazy="`${images}/icon/${item.electricity ? 'square-checked' : 'square-un-check'}.svg`"
                  alt="icon check"
                >
              </td>
              <td>
                <img
                  v-lazy="`${images}/icon/${item.water ? 'square-checked' : 'square-un-check'}.svg`"
                  alt="icon check"
                >
              </td>
              <td>
                <img
                  v-lazy="`${images}/icon/${item.supporting_facilities ? 'square-checked' : 'square-un-check'}.svg`"
                  alt="icon check"
                >
              </td>
              <td>{{ item.partner_assistant_name || '-' }}</td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-if="isCanAccessUser(`delete`, `Akuisisi`)"
                  v-lazy="`${images}/icon/trash.svg`"
                  class="uk-margin-small-right img-click"
                  alt="icon delete"
                  @click="handleActions('delete', item)"
                >
                <img
                  v-if="isCanAccessUser(`edit`, `Akuisisi`)"
                  v-lazy="`${images}/icon/edit-black.svg`"
                  class="img-click"
                  alt="icon edit"
                  @click="handleActions('edit', item.id)"
                >
                <img
                  v-if="isCanAccessUser(`view`, `Akuisisi`)"
                  v-lazy="`${images}/icon/eye.svg`"
                  class="uk-margin-small-left img-click"
                  alt="icon view"
                  @click="handleActions('view', item.id)"
                >
              </td>
            </tr>
          </tbody>
          <template v-else>
            <empty-table :colspan="14" />
          </template> 
        </template>
        <template v-else-if="dataListAcquisition.isLoading">
          <loading-table :colspan="14" />
        </template>
        <!-- Response for modal confirm delete -->
        <modal-delete-confirm :props-payload="propsPayload" />
      </table>
    </div>
    <pagination
      :total-data="dataList.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="dataList.meta.current_page"
      :current-limit="dataList.meta.limit"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { isCanAccess } from '@/utils/auth'
import LoadingTable from '@/components/globals/table/LoadingTable'
import EmptyTable from '@/components/globals/table/EmptyTable'
import ModalDeleteConfirm from '@/components/globals/modal/DeleteConfirm'
import { PREFIX_IMAGE } from '@/utils/constant'
import Pagination from '@/components/globals/Pagination'

export default {
  components: {
    Pagination,
    LoadingTable,
    EmptyTable,
    ModalDeleteConfirm
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      propsPayload: {},
      dataListAcquisition: {
        isLoading: true,
        data: [],
        meta: {
          total_count: 0
        }
      },
      tableProps: {
        total_count: 0
      }
    }
  },
  computed : {
    ...mapGetters({
      dataList: 'acquisition/dataList',
      dataMeta: 'acquisition/dataMeta',
      dataDelete: 'acquisition/dataDelete'
    })
  },
  watch: {
    dataList() {
      this.dataListAcquisition = {
        isLoading: false,
        meta: this.dataList.data ? this.dataList.meta : {},
        data: this.dataList.data ? this.dataList.data : []
      }
      this.tableProps ={
        ...this.dataMeta,
        meta: this.dataMeta,
        setMeta: this.setDataMeta,
        total_count: this.dataList.meta ? this.dataList.meta.total_count : 0
      }
    }
  },
  methods: {
    changeLimit(e) {
      this.setDataMeta({
        ...this.dataMeta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.dataListAcquisition.isLoading = true
      this.setDataMeta({
        ...this.dataMeta,
        page: value
      })
    },
    ...mapActions({
      getDeleteAcquisition: 'acquisition/getDeleteAcquisition',
      getDataList: 'acquisition/getDataList'
    }),
    ...mapMutations({
      setDataMeta: 'acquisition/SET_DATA_META'
    }),
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    handleActions(field, payload) {
      if (field == 'delete') {
        this.propsPayload = {
          title: 'Akuisisi',
          description: `Mitra ${payload.name}`,
          function: this.getDeleteAcquisition,
          id: payload.id,
          getters: 'acquisition/dataDelete',
          nextPath: '/admin/akuisisi',
          fetchingData: this.getDataList
        }
        window.UIkit.modal('#modal-delete-confirm').show()
      } else if (field == 'view') {
        this.$router.push(`/admin/akuisisi/detail/${payload}`)
      } else {
        this.$router.push(`/admin/akuisisi/edit/${payload}`)
      }
    }
  }
}
</script>
