<template>
  <MainCard
    :data-header="dataHeader"
  >
    <div class="uk-margin-top uk-margin-bottom">
      <div class="uk-card uk-card-default uk-padding-small kandang-card">
        <filter-list />
        <list-table />
      </div>
    </div>
  </MainCard>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import getHeaderTitle from '@/utils/header-title'
import MainCard from '@/components/globals/card'
import ListTable from './list'
import FilterList from './list/filter'

export default {
  components: {
    MainCard,
    ListTable,
    FilterList
  },
  data() {
    return {
      dataHeader: {
        title: '',
        isBreadcrumb: false,
        breadcrumb: ''
      }
    }
  },
  computed : {
    ...mapGetters({
      dataList: 'acquisition/dataList',
      dataMeta: 'acquisition/dataMeta'
    })
  },
  watch: {
    async dataMeta() {
      await this.getDataList(this.dataMeta)
    }
  },
  async mounted() {
    await this.getDataList(this.dataMeta)
    this.dataHeader = {
      ...this.dataHeader,
      title: getHeaderTitle(this.$route.fullPath)
    }
  },
  methods: {
    ...mapActions({
      getDataList: 'acquisition/getDataList'
    }),
    ...mapMutations({
      setDataMeta: 'acquisition/SET_DATA_META'
    })
  }
}
</script>
